.navbar-vertical .navbar-nav>.nav-item .nav-link.active .icon {
  background-image: linear-gradient(310deg,#d0ea63,#6da021) !important;
}
.bg-gradient-primary {
  /* background-image: linear-gradient(
310deg
,#7928ca,#ff0080); */
  background-image: linear-gradient(
310deg
,#d0ea63,#71a81d) !important;
}