
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Quicksand:wght@300&family=Raleway:ital,wght@0,400;1,700&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root{
--amplify-primary-color:#85B767;
--amplify-primary-tint:#85B767;
--amplify-primary-shade:#85B767;

}
.cursor-pointer{
  cursor: pointer;
}
.MuiCircularProgress-colorPrimary {
  color: #85b767 !important;
}
.text-default {
  color: #85b767 !important;
}
.bg-primary {
  background-color: #85b767!important;
}
.btn-primary {
  color: #fff;
  background-color: #85B767 !important;
  border-color: #85B767 !important;
  font-family: 'Raleway';
}
.btn-primary:hover {
  color: #fff !important;
  background-color: #85b767 !important;
  font-family: 'Raleway';
}
button.btn-default {
  border: 1px solid #85B767;
  font-family: 'Raleway';
}
button.btn-default:hover {
  background-color: #85B767 ;
  color: #fff;
}
.MuiAppBar-colorPrimary {
  color: #676767 !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
}
.desktop-navbar {
  display: block;
}
.mobile-navbar {
  display: none;
}
.custom-search{
  height: 50px;
  display: flex;
  box-shadow: -1px 2px 5px 0px #bfbfbf;
  border-radius: 30px;
  padding: 0px !important;
}
.custom-search-icon img {
  width: 65px;
  margin-top: -5px;
  margin-right: -8px;
  height: 65px;
}
.appbar-menu-links {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appbar-menu-links ul{
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.appbar-menu-links ul li{
  padding: 8px 30px;
  color: #676767;
  font-family: 'Quicksand', sans-serif;
}
.appbar-menu-links ul li a{
  text-decoration: none;
  color: #676767;
}
.appbar-menu-links ul li:hover a{
  color: #85b767;
  font-weight: 800;
}
.appbar-menu-links ul li:hover {
  cursor: pointer;
  transition: 0.1s;
  margin-bottom: 3px !important;
border-top: 3px solid #85b767;
}
.cart-icon a {
  padding: 0 !important;
}
.cart-icon .MuiBadge-anchorOriginTopRightRectangle {
  top: 10px;
  right: 10px;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  position: absolute;
}
.footer_component {
  background: #85b767;
  padding: 50px 25px;
}
.footer_links {
  text-align: center;
  line-height: 2;
}
.footer_links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.product-list{
  height: 600px;
  overflow-y: scroll;
}
button.add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: #abd467;
  color: #fff;
  font-weight: 800;
  border: none;
  font-size: 25px;
  border-radius: 5px;
  margin: 0px 5px;
  padding: 0px 10px;
}
button.remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: #abd467;
  color: #fff;
  font-weight: 800;
  border: none;
  font-size: 25px;
  border-radius: 5px;
  margin: 0px 5px;
  padding: 0px 10px;
}
button.remove, button.add:hover{
  background: #944a36;
}
.adyen-checkout__button {
  background: #85b767!important;
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  height: 48px;
  margin: 0;
  padding: 15px;
  text-decoration: none;
  transition: background .3s ease-out,box-shadow .3s ease-out;
  width: 100%;
}
@media only screen and (max-width: 678px) {
  .desktop-navbar {
    display: none;
}
.mobile-navbar {
  display: block;
}
.custom-search {
  height: 40px;
  box-shadow: 0px 0px 5px 0px #d9d9d9;
}
.drawerapp-list svg {
  fill: #abd467;
}
.custom-search-icon img {
  width: 55px;
  height: 55px;
}
.drawerapp-list ul span {
  color: #abd467 !important;
}
  .menus.appbar-menu-links {
    display: none;
}
.footer_links {
  margin-top: 20px;
  text-align: left;
}
img.small {
  width: 100%;
}
}
/* Index page */

.main-container {
  margin: auto;
  max-width: 1048px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}

.integration-list {
  display: flex;
  margin-top: 6%;
  max-width: 1048px;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
img.pay-logo {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .integration-list {
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px;
  }
}

@media (min-width: 1024px) {
  .integration-list {
    margin-left: -16px;
    margin-bottom: -16px;
    margin-right: -16px;
  }
}

.integration-list-item {
  background: #f7f8f9;
  border-radius: 6px;
  flex: 1 1 0;
  margin: 4px;
  min-width: 40%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f7f8f9;
}

.integration-list-item:hover {
  box-shadow: 0 16px 24px 0 #e5eaef;
  text-decoration: none;
  border: 1px solid #06f;
}

@media (min-width: 768px) {
  .integration-list-item {
    margin-left: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
    margin-top: 16px;
    min-width: 25%;
  }
}

.integration-list-item-link {
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .integration-list-item-link {
    padding-left: 28px;
    padding-bottom: 28px;
    padding-right: 28px;
    padding-top: 28px;
  }
}

.integration-list-item-title {
  margin: 0;
  text-align: center;
  color: #00112c;
  font-size: 1em;
  font-weight: 700;
  margin: 10px 0 0;
}

@media (min-width: 768px) {
  .integration-list-item-title {
    font-size: 24px;
    margin-left: 0;
    margin-bottom: 6px;
    margin-right: 0;
  }
}

.integration-list-item-subtitle {
  color: #00112c;
  font-size: 0.67em;
  font-weight: 700;
  margin: 10px 0 0;
}

@media (min-width: 768px) {
  .integration-list-item-subtitle {
    font-size: 16px;
    margin-left: 0;
    margin-bottom: 6px;
    margin-right: 0;
  }
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  margin-top: 10%;
  color: #00112c;
}

/* end Index page */

/* Cart preview page */

.shopping-cart {
  float: right;
}
@media (min-width: 768px) {
  .shopping-cart {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 3px;
  }
}
.shopping-cart-link {
  display: inline-block;
  position: relative;
}
.order-summary-list {
  border-top: 1px solid #e6e9eb;
}
.order-summary-list-list-item {
  border-bottom: 1px solid #e6e9eb;
  display: flex;
  height: 97px;
}
.order-summary-list-list-item-image {
  height: 64px;
  margin: 16px;
  width: 64px;
}
.order-summary-list-list-item-title {
  font-weight: 700;
  margin: auto auto auto 0;
}
.order-summary-list-list-item-price {
  color: #687282;
  margin: auto 16px;
  text-align: right;
  width: 80px;
}
@media (min-width: 768px) {
  .order-summary-list-list-item-price {
    margin-left: 24px;
    margin-bottom: auto;
    margin-right: 24px;
    margin-top: auto;
  }
}
.order-summary-list-list-item-remove-product {
  background: none;
  border: 0;
  cursor: pointer;
  height: 25px;
  margin: auto 0;
  padding: 0;
  width: 25px;
}
.cart {
  text-align: center;
  margin-top: 80px;
}
.cart-footer {
  font-weight: 700;
  margin-top: 17px;
  text-align: right;
}
@media (min-width: 768px) {
  .cart-footer {
    margin-top: 24px;
  }
}
.cart-footer .button {
  margin-top: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .cart-footer .button {
    margin-top: 0;
    width: 288px;
  }
}
.cart-footer-amount {
  margin-left: 16px;
  margin-right: 24px;
}
.whole-preview {
  margin: auto;
  max-width: 1110px;
  padding: 0 16px;
}
@media (min-width: 1440px) {
  .whole-preview {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

/* end of Cart preview page */

/* Payment page */

#payment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#payment-page .container {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  padding-left: 8px;
  padding-right: 8px;
}

.checkout-component {
  background: #f7f8f9;
  border: 1px solid #e6e9eb;
  border-radius: 12px;
  margin: 8px 0;
}

/* Adyen Components */
.payment {
  width: 100%;
  padding-top: 0px !important;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 1076px) {
  #payment-page .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payment {
    align-self: center;
    max-width: 610px;
  }
}

.payment-container {
  display: flex;
  justify-content: center;
  background: #f7f8f9;
  border: 1px solid #e6e9eb;
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  height: 100%;
}

/* end Payments page */

/* Dropin page */

#dropin {
  width: 100%;
  max-width: 450px;
}

@media screen and (max-width: 1076px) {
  #dropin {
    width: 100%;
    max-width: 610px;
  }
}

/* end Dropin page */

/* Status page */

.status-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  margin: 100px 0 126px;
  text-align: center;
}

.status .status-image {
  display: block;
  height: 100px;
  margin: 16px auto 0;
}

.status .status-image-thank-you {
  height: 66px;
}

.status .status-message {
  margin: 8px 0 24px;
}

.status .button {
  max-width: 236px;
}

@media (min-width: 768px) {
  .status .button {
    max-width: 200px;
  }
}

/* end Status page */
