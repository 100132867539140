.MuiStepIcon-root.MuiStepIcon-active {
    color: #85b767 !important; 
}
.MuiStepIcon-root.MuiStepIcon-completed {
    color: #85b767 !important;
}
.MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #85b767 !important;
    pointer-events: none;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgb(133 183 103) !important;
}
.MuiFormLabel-root.Mui-focused {
    color: #85b767 !important;
}
table.CartTable {
    border: 1px solid #85b767;
}
table.CartTable tr th {
    padding: 5px 10px;
    background: #85b767;
}
table.CartTable tr td{
    padding: 5px 10px;
}
table.CartTable tr th h5{
   color: #fff;
   margin: 0;
}