.auth-page-header{
  background-image: url('../../assets/img/bg1.jpg');
}
.signup-page .bg-gradient-dark {
  background-image: linear-gradient(310deg,#bc3c3c,#1bff50) !important;
}
.signin-page .bg-gradient-dark {
  background-image: linear-gradient(310deg,#bc3c3c,#1bff50) !important;
}
.sign-background{
  background-image: url('../../assets/img/bg1.jpg');
}